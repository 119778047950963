export default {
    name: 'admin-visitor',
    created() {
        this.model =  'Admin.' + this.$route.meta.model;
    },
    computed:{
        can_copy(){
            return this.$route.meta?this.$route.meta.copy:false;
        },
        can_add(){
            return this.$route.meta?!this.$route.meta.no_add:true
        },
        can_delete(){
            return this.$route.meta?!this.$route.meta.no_delete:true
        },
        can_edit(){
            return this.$route.meta?!this.$route.meta.no_edit:true
        },
        modelImportUrl() {
            return this.$apiUrl(this.model + '.Import');
        },
    },
    methods:{
        goDownload(){
            window.open('/files/courses用户导入样本.xlsx');
        },
    }
}
