<template>
    <section class="">
        <front-table :front_page="page"
                     :front_records="records"
                     :front_search="search"
                     :front_header="header"
                     :model="model"
                     @reload="handleReload"
                     @edit="handleEdit"
                     :btn_copy = "can_copy"
                     @copy="handleCopy"
                     :btn_add = "can_add"
                     :btn_delete="can_delete"
                     :btn_edit="can_edit"
        >
            <div class="pull-right d-flex" style="padding-top: 2px;">
                    <el-button size="medium" type="success" class="mr-2" @click="goImportVisitor"
                               icon="el-icon-upload2">{{('导入用户')}}
                    </el-button>
            </div>

        </front-table>
        <!--编辑界面-->
        <edit-dialog :edit_mode="edit_mode" :model="model" :single="single" :fields="fields" dual
                     @success="handleSuccess" @cancel="handleCancel"></edit-dialog>

        <div class="clearfix"></div>
    </section>
</template>

<script>
    import EditDialog from "../components/edit-dialog";
    import FrontTable from "../components/front-table";
    import FrontMixins from "../common/mixins/front-mixins";
    import AdminVisitor from "./mixins/admin-visitor-mixin";
    export default {
        mixins: [FrontMixins,AdminVisitor],
        components: {FrontTable, EditDialog},
        methods:{
            goImportVisitor(){
                this.$router.push('/admin/import-visitor');
            }
        }
    }

</script>

<style lang="scss">

</style>
